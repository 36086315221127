
.image {
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.imgLogo {
  height: 100px;
  width: 100px;
}

.btn {
  padding: 20px;
}

fieldset {
  margin-top: 20px;
  border: 1px #ddd solid;
}

.q-title {
  font-size: 16px;
  font-weight: 600;
}

textarea {
  margin-top: 15px;
  max-height: 100px;
  min-height: 50px;
  max-width: 280px;
  min-width: 280px;
}